import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function ContactSection() {
    const {t} = useTranslation(['main']);

    const styles = {
        mainContainer: {
            width:{xs:'80%',sm:'90%',md:'60%'},
            display:'flex',
            flexDirection:{xs:'column',sm:'row',md:'row'},
            alignItems:{xs:'start',sm:'start',md:'center'},
            justifyContent:'space-between',
            gap:{xs:'100px',sm:'30px',md:'30px'},
            mx:'auto',
            my:'30px',
        },
        textContainer: {
            display:'flex',
            flexDirection:'column',
            alignItems:'start',
            gap:'10px'

        },
        title: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '32px',
            letterSpacing: '0.1px',
            color: '#252B42',
        },
        subtitle: {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.2px',
            color: '#737373',
        },
        button: {
            backgroundColor: '#FFA62B',
            color:'#fff',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '28px',
            textAlign: 'center',
            letterSpacing: '0.2px',
            color: '#FFFFFF',
            padding:'15px 40px'
        }
    }
    return (
        <Box
            maxWidth="lg"
            sx = {styles.mainContainer}
        >
            <Box sx = {styles.textContainer}>
                <Typography sx = {styles.title}>{t('contactUsTitle')}</Typography>
                <Typography sx = {styles.subtitle}>{t('contactUsCaption')}</Typography>                
            </Box>
            <Button sx = {styles.button}>{t('contactUsBtnCaption')}</Button>
        </Box>
    )
}