import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SubmitCard from './SubmitCard';

import telIcon from './../assets/icons/getInTochIconTel.png';
import locIcon from './../assets/icons/getInTochIconLoc.png';
import sendIcon from './../assets/icons/getInTochIconSend.png';


export default function GetInTouchSection() {
    const {t} = useTranslation(['main']);
    const styles = {
        mainContainer:{
            mx:'auto',
            mb:'100px',
        },
        titlesContainer: {

        },
        title: {
            width:{xs:'100%',sm:'100%',md:'480px'},
            mx:'auto',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '40px',
            lineHeight: '57px',
            letterSpacing: '0.2px',
            color: '#252B42',
            textAlign:'center',
        },
        subTitle: {
            width:{xs:'80%',sm:'40%',md:'511px'},
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            letterSpacing: '0.2px',
            color: '#737373',
            mx:'auto',
            mt:'10px',
        },
        cardsContainer: {
            display:'flex',
            flexDirection:{xs:'column',sm:'column',md:'row'},
            alignItems:'center',
            justifyContent:'center',
            mt:'80px'
        }


    }
    return  (
        <Box
            id = "main-container"
            maxWidth="lg"
            sx = {styles.mainContainer}
        >
            <Box
                id = "titles-container"
                sx = {styles.titlesContainer}
            >
                <Typography sx = {styles.title}>{t('getInTouchSectionHeaders.0.title')}</Typography>
                <Typography sx = {styles.subTitle}>{t('getInTouchSectionHeaders.1.caption')}</Typography>
            </Box>
            <Box
                id = "score-cards-container"
                sx = {styles.cardsContainer}
            >
                <SubmitCard 
                    boxHeight = {'333px'}
                    email1  = {
                        {
                            text: t('getInTouchBox1.0.email1'),
                            color:'#252B42',
                            fontFamily: 'inherit',
                            fontSize:'14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                        }
                    }
                    email2 = {
                        {
                            text: t('getInTouchBox1.1.email2'),
                            color:'#252B42',
                            fontFamily: 'inherit',
                            fontSize:'14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                        }
                    }
                    title = {
                        {
                            text: t('getInTouchBox1.2.title'),
                            color:'#252B42',
                            fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    btnText = {
                        {
                            text:  t('getInTouchBox1.3.btnCaption'),
                            color:'#000',
                            fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '32px',
                        }
                    }
                    btnColor = {'#FFA62B'}
                    logo = {telIcon}
                    bgColor = {'#fff'}
                    elevation = {1}
                    borderRadius = {0}
                />
                <SubmitCard 
                    boxHeight = {'393px'}
                    email1  = {
                        {
                            text: t('getInTouchBox2.0.email1'),
                            color:'#fff',
                            fontFamily: 'inherit',
                            fontSize:'14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                        }
                    }
                    email2 = {
                        {
                            text: t('getInTouchBox2.1.email2'),
                            color:'#fff',
                            fontFamily: 'inherit',
                            fontSize:'14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                        }
                    }
                    title = {
                        {
                            text: t('getInTouchBox2.2.title'),
                            color:'#fff',
                            fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    btnText = {
                        {
                            text: t('getInTouchBox2.3.btnCaption'),
                            color:'#fff',
                            fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '32px',
                        }
                    }
                    btnColor = {'#fff'}
                    logo = {locIcon}
                    bgColor = {'#252B42'}
                    elevation = {1}
                    borderRadius = {0}
                />
                <SubmitCard 
                    boxHeight = {'333px'}
                    email1  = {
                        {
                            text: t('getInTouchBox3.0.email1'),
                            color:'#252B42',
                            fontFamily: 'inherit',
                            fontSize:'14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                        }
                    }
                    email2 = {
                        {
                            text: t('getInTouchBox3.1.email2'),
                            color:'#252B42',
                            fontFamily: 'inherit',
                            fontSize:'14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                        }
                    }
                    title = {
                        {
                            text: t('getInTouchBox3.2.title'),
                            color:'#252B42',
                            fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    btnText = {
                        {
                            text: t('getInTouchBox3.3.btnCaption'),
                            color:'#000',
                            fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '32px',
                        }
                    }
                    btnColor = {'#FFA62B'}
                    logo = {sendIcon}
                    bgColor = {'#fff'}
                    elevation = {1}
                    borderRadius = {0}
                />

            </Box>

        </Box>
    )
}