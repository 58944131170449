import { useTranslation } from "react-i18next"
import { useMediaQuery,useTheme } from "@mui/material";
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import SimpleCard from "./SimpleCard"

// import heroImagertl from './../assets/heroImage.jpg';
// import heroImageltr from './../assets/heroImage.jpg';
import heroImagertl from './../assets/heroImage-rtl.png'
import heroImageltr from './../assets/heroImage-ltr.png'
import icon1 from './../assets/icons/icon1.png';
import icon2 from './../assets/icons/icon2.png';
import icon3 from './../assets/icons/icon3.png';



export default function HomePageHeroSection() {

    const {t,i18n} = useTranslation(['main']);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('sm'));
    const isSM = useMediaQuery(theme.breakpoints.between('sm','md'));
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    const styles = {
        mainContainer: {
            // position:'absolute',
            // top:'0',
            // left:'0',
            backgroundImage: `url(${i18n.dir() === 'ltr' ? heroImageltr : heroImagertl})`,
            backgroundSize:'cover',
            backgroundPosition:{xs:'right',sm:'center',md:'right'},
            backgroundRepeat:'no-repeat',
            width:'100%',
            paddingBottom:{xs:'300px',sm:'100px',md:'0'},
            border:'1px solid black',
            height:{xs:'100%',sm:'0',md:'100vh'},
            zIndex:-10,
        },
        heroSection: {
            margin:'0 auto',
            padding: isXS ? '300px 35px 0 35px' :
                     isSM ? '300px 100px 0 100px' :
                     isMD ? '300px 0 0 0' : '300px',
        },
        heroTextsContainer:{

        },
        mainTitle: {
            width:{xs:'100%',sm:'100%',md:'509px'},
            fontStyle: 'normal',
            fontWeight: {xs:'700',sm:'50px',md:'800'},
            fontSize: {xs:'30px',sm:'50px',md:'58px'},
            lineHeight: {xs:'57px',sm:'50px',md:'80px'},
            letterSpacing: '0.2px',
            color: '#252B42',
            textAlign: {xs:'center',sm:'center',md:'justify'},
        },
        subTitle: {
            width: {xs:'100%',sm:'100%',md:'376px'},
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: {xs:'16px',sm:'18px',md:'20px'},
            lineHeight: '30px',
            letterSpacing: '0.2px',
            color: '#737373',
            textAlign: {xs:'center',sm:'center',md:'justify'},
            my:'35px'
        },
        heroButtonsContainer: {
            display:'flex',
            flexDirection:{xs:'column',sm:'row',md:'row'},
            alignItems:'center',
            justifyContent:{xs:'center',sm:'center',md:'start'},
            gap:'30px',
        },
        qouteButton: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '28px',
            textAlign: 'center',
            letterSpacing: '0.2px',
            color: '#FFFFFF',
            borderRadius:'37px',
            padding:'10px 36px',
            backgroundColor:'#FFA62B',
            '&:hover':{
                backgroundColor: '#FFA62B90',
                color:'#fff'
            }
        },
        learnButtons: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '28px',
            textAlign: 'center',
            letterSpacing: '0.2px',
            color: '#00A0C1',
            border:'1px solid #00A0C1',
            borderRadius:'37px',
            padding:'10px 36px',
            backgroundColor:'transparent',
            '&:hover': {
                backgroundColor:'#00A0C1',
                color:'#fff'
            }
        },
        heroCardsContainer: {
            display:'flex',
            flexDirection:{xs:'column',sm:'column',md:'row'},
            alignItems:'center',
            gap:'30px',
            mt:'80px',
            mb: {xs:'200px',sm:'200px',md:'0'},
            transform: isXS ? 'translate(0,0)' :
                       isSM ? 'translate(0,0)' :
                       isMD ? 'translate(0,30px)' : 'translate(0,0)',
        }

    }
    return (
        <Box
            id = "main-container"
            style = {styles.mainContainer}
        >
            <Box
                id = "hero-section"
                maxWidth="lg"
                style = {styles.heroSection}
            >
                <Box
                    id = "hero-texts-container"
                    sx = {styles.heroTextsContainer}
                >
                    <Typography sx = {styles.mainTitle}>{t('heroTitle')}</Typography>
                    <Typography sx = {styles.subTitle}>{t('heroText')}</Typography>
                </Box>
                <Box
                    id = "hero-buttons-container"
                    sx = {styles.heroButtonsContainer}
                >
                    <Button sx = {styles.qouteButton}>{t('qouteBtn')}</Button>
                    <Button sx = {styles.learnButtons}>{t('learnBtm')}</Button>
                </Box>
                <Box
                    id = "hero-cards-container"
                    sx = {styles.heroCardsContainer}
                >
                    <SimpleCard 
                            index = {1}
                            title = {
                                {
                                    text: t('heroBox1.0.title'),
                                    // fontFamily:'inherit',
                                    fontSize:'24px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '32px',
                                    color:'#252B42'
                                }
                            }
                            subtitle = {
                                {
                                    text: ' ',
                                    // fontFamily:'inherit',
                                    fontSize:'24px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '32px',
                                    color:'#252B42'
                                }
                            } 
                            text ={
                                {
                                    text: t('heroBox1.1.caption'),
                                    // fontFamily:'inherit',
                                    fontSize:'14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                    color:'#737373'
                                }
                            }
                            logo = {icon1}
                            bgColor = {'#fff'}
                            elevation = {10}
                            borderRadius ={0}/>
                    <SimpleCard 
                            index = {2}
                            title = {
                                {
                                    text: t('heroBox2.0.title'),
                                    // fontFamily:'inherit',
                                    fontSize:'24px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '32px',
                                    color:'#252B42'
                                }
                            }
                            subtitle = {
                                {
                                    text: ' ',
                                    // fontFamily:'inherit',
                                    fontSize:'24px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '32px',
                                    color:'#252B42'
                                }
                            } 
                            text ={
                                {
                                    text: t('heroBox2.1.caption'),
                                    // fontFamily:'inherit',
                                    fontSize:'14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                    color:'#737373'
                                }
                            }
                            logo = {icon2}
                            bgColor = {'#fff'}
                            elevation = {10}
                            borderRadius ={0}/>
                    <SimpleCard 
                            index = {3}
                            title = {
                                {
                                    text: t('heroBox3.0.title'),
                                    // fontFamily:'inherit',
                                    fontSize:'24px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '32px',
                                    color:'#fff'
                                }
                            }
                            subtitle = {
                                {
                                    text: ' ',
                                    // fontFamily:'inherit',
                                    fontSize:'24px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '32px',
                                    color:'#fff'
                                }
                            } 
                            text ={
                                {
                                    text: t('heroBox3.1.caption'),
                                    // fontFamily:'inherit',
                                    fontSize:'14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                    color:'#fff'
                                }
                            }
                            logo = {icon3}
                            bgColor = {'#00A0C1'}
                            elevation = {10}
                            borderRadius ={0}/>

                </Box>
            </Box>
        </Box>
    )
}