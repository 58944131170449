import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Logo() {
    const styles = {
        mainContainer: {
        },
        text: {
            color:'#000',
            fontFamily:'Montserrat',
            fontSize:'24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '32px',
            letterSpacing: '0.1px',
            color: '#252B42',
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Typography sx = {styles.text}>Indicorp</Typography>
        </Box>
    )

}