import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function SocialMediaIconsBar() {
  const styles = {
    mainContainer: {
    }
  }
  return (
    <Box sx={styles.mainContainer}>
      <Stack spacing={2} direction="row">
        <FacebookIcon color="primary" />
        <InstagramIcon color="error"/>        
        <XIcon color="action"/>
        <YouTubeIcon color="error" />
      </Stack>
    </Box>
  );
}
