import * as React from 'react';
import { useMediaQuery,useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardMedia } from '@mui/material';
import { Height } from '@mui/icons-material';



export default function SimpleCard({index,title,subtitle,text,logo,bgColor,elevation,borderRadius}) {


  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  const isSM = useMediaQuery(theme.breakpoints.between('sm','md'));
  const isMD = useMediaQuery(theme.breakpoints.up('md'));


  
    const styles = {
        mainContainer: {
            width:{xs:'100%',sm:'50%',md:'328px'},
            height:'210px',
            // minWidth: 275,
            backgroundColor:bgColor,
            borderRadius: (borderRadius === null) ? 0 : borderRadius,
            padding: isXS ? '10px 0 0 20px' :
                     isSM ? '10px 0 0 20px' : 
                     isMD ? '10px 30px' : '0',
            alignSelf: isSM && (index % 2 === 0) ? 'start' : 'end'
        },
        title: {
            color:title.color,
            fontFamily: title.fontFamily,
            fontSize:title.fontSize,
            fontStyle: title.fontStyle,
            fontWeight: title.fontWeight,
            lineHeight: title.lineHeight,
        },
        subtitle: {
            color:subtitle.color,
            fontFamily: subtitle.fontFamily,
            fontSize:subtitle.fontSize,
            fontStyle: subtitle.fontStyle,
            fontWeight: subtitle.fontWeight,
            lineHeight: subtitle.lineHeight,
            mb: '30px'
        },
        text: {
            color:text.color,
            fontFamily: text.fontFamily,
            fontSize:text.fontSize,
            fontStyle: text.fontStyle,
            fontWeight: text.fontWeight,
            lineHeight: text.lineHeight,
        }
    }

  return (
    <Card  elevation = {elevation} sx={styles.mainContainer}>
      <CardMedia 
        component="img"
        sx = {{
          width:{xs:'40px',sm:'40px',md:'60px'},
          mt:{xs:'20px',sm:'20px',md:'30px'},
          mx:'0px'

        }}
        image={logo}
      />
      <CardContent sx = {{mx:'0px',px:'0'}}>
        <Typography id = 'title' sx={styles.title} gutterBottom>
          {title.text}
        </Typography>
        <Typography id = 'subtitle' sx={styles.subtitle} component="div">
          {subtitle.text}
        </Typography>
        <Typography id = 'text' sx={styles.text}>
          {text.text}
        </Typography>
      </CardContent>
    </Card>
  );
}
