import { useTranslation } from 'react-i18next';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import {mainMenuItems} from './../MainMenuItems';
import Logo from './../components/Logo';
import TemporaryDrawer from './TemporaryDrawer';

import { Link } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function NavBar() {
  const {t,i18n} = useTranslation(['navbar'])
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  const styles = {
    appbar: {
      backgroundColor:'transparent',
      py:'30px',
      boxShadow:'none',
      position:'absolute',
      top:'0',
    },
    secondContainer: {
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      width:'90%',
      mx:'auto'
    },
    menu: {
      flexGrow: 1,
      display: { xs: 'none', md: 'flex' },
      justifyContent:'start',
      alignItems:'center',
      gap:'20px',
      mx:'80px'
    },
    drawerContainer: {
      flexGrow: 1,
      display: { xs: 'flex',sm:'flex', md: 'none' },
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'end'
    },
    linkText: {
      color:'#000',
      fontFamily: i18n.language === 'fa' ? "Vazirmatn" : "Montserrat", 
      fontSize:'14px',
      lineHeight:'24px',
      fontWeight:'600',
      fontStyle: 'normal',
    }
  }
  return (
    <AppBar position="static" sx = {styles.appbar}>
      <Box sx = {styles.secondContainer}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Link to = "/" style = {{textDecoration:'none'}}>
              <Box 
                sx = {{
                  display:'flex',
                  alignItems:'end',
                  gap:'15px',
                  width: {xs: '100px', sm:'130px', md: '150px'},
                  direction: 'ltr'
                }}
              >
                <Logo />
              </Box>
            </Link>
            <Box sx={styles.menu}>
              {mainMenuItems.map((item) => (
                <Button
                  key={item}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block'}}
                >
                  <Link to = {'/' + item} style = {{textDecoration:'none'}}>
                    <Typography sx = {styles.linkText}
                    >
                      {t(item.toString())}
                    </Typography>
                  </Link>
                </Button>
              ))}
                
            </Box>
            <Box sx={styles.drawerContainer}>
              <TemporaryDrawer />
            </Box>
          </Toolbar>
        </Container>
        <Box sx = {{display:{xs:'none',sm:'none', md:'block'}}}>
          <LanguageSelector />
        </Box>
      </Box>
    </AppBar>
  );
}
export default NavBar;