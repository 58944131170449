import Box from "@mui/material/Box";
import ContactSection from "./ContactSection";
import FooterMenuBar from "./FooterMenuBar";
import Copyright from "./Copyright";

import FooterSubmenu from "./FooterSubmenu";



export default function Footer() {
    
    const styles = {
        mainContainer: {
            
        }
    }
    return (
        <Box
            id = "main-container"
            style = {styles.mainContainer}
        >
            <Box
                id = "hero-section"
                style = {styles.heroSection}
            >
                <ContactSection />
                <FooterMenuBar />
                <Copyright />
            </Box>
        </Box>
    )
}