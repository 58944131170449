import { useTranslation } from "react-i18next";
import { CardContent, CardMedia } from "@mui/material"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"

import GroupsIcon from '@mui/icons-material/Groups';
import SpeedIcon from '@mui/icons-material/Speed';

import video1 from './../assets/video/Smart Meeting Room.mp4'
import posterImage from './../assets/video/Smart Meeting Room-poster.png';

export default function VideoPlayer() {
    const {t} = useTranslation(['main']);

    const styles = {
        card: {
            display:'flex',
            flexDirection:{xs:'column',sm:'column',md:'row'},
            gap:'120px',
            mt:'100px',
        },
        title: {
            width:{xs:'100%',sm:'100%',md:'333px'},
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'30px',sm:'30px',md:'30px'},
            lineHeight: '57px',
            letterSpacing: '0.2px',
            color: '#252B42',
            textAlign:{xs:'center',sm:'center',md:'justify'},
        },
        text: {
            width:{xs:'100%', sm:'80%',md:'333px'},
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.2px',
            color: '#737373',
            textAlign:{xs:'center',sm:'center',md:'justify'},            
            mx:'auto',
            mt:'10px'
        },
        featuresMainContainer: {
            width:'100%',
            display:'flex',
            flexDirection:{xs:'column',sm:'row',md:'column'},
            alignItems:'center',
            justifyContent:'space-between',
            
        },
        featuresContainer: {
            display:'flex',
            flexDirection:'row',
            gap:'20px',
            my:'30px',
        },
        icons: {
            color: '#FFA62B'
        },
        video: {
            width:'90%',
            mx:'auto'
            // padding:'0 30px 0 30px'
        },
        featuresBox: {
            width:'100%',
        }

    }
    return (
        <Card sx = {styles.card}>
            <CardMedia 
                component="video"
                controls
                // autoPlay
                height="auto"
                width = "100%"
                src = {video1}
                title = {'Smart Meeting Room'}
                poster = {posterImage}
                sx = {styles.video}
            />
            <CardContent>
                <Typography sx = {styles.title}>{t('videoTitle')}</Typography>
                <Typography sx = {styles.text}>{t('videoCaption')}</Typography>
                <Box sx = {styles.featuresMainContainer}>
                    <Box sx = {styles.featuresContainer}>
                        <GroupsIcon sx = {styles.icons} />
                        <Box sx = {styles.featuresBox}>
                            <Typography>{t('videoFeature1.0.title')}</Typography>
                            <Typography>{t('videoFeature1.1.caption')}</Typography>
                        </Box>
                    </Box>
                    <Box sx = {styles.featuresContainer}>
                        <SpeedIcon sx = {styles.icons} />
                        <Box sx = {styles.featuresBox}>
                        <Typography>{t('videoFeature2.0.title')}</Typography>
                        <Typography>{t('videoFeature2.1.caption')}</Typography>
                        </Box>
                    </Box>

                </Box>
            </CardContent>
        </Card>

    )
}