const footerMenuItems = [
    {
        translateName: "footerMenuCompanyInfo",
        title: 'Company Info',
        subItems: [
            'About us',
            'Carrier',
            'We are hiring',
            'Blog'
        ]
    },
    {
        translateName: "footerMenuLegal",
        title: 'Legal',
        subItems: [
            'About us',
            'Carrier',
            'We are hiring',
            'Blog'
        ]
    },
    {
        translateName: "footerMenuFeatures",
        title: 'Features',
        subItems: [
            'Business Marketing',
            'User Analytic',
            'Live Chat',
            'Unlimited Support'
        ]
    },
    {
        translateName: "footerMenuResources",
        title: 'Resources',
        subItems: [
            'IOS & Android',
            'Watch a Demo',
            'Customers',
            'API'
        ]
    },
    {
        translateName: "footerMenuContact",
        title: 'Get In Touch',
        subItems: [
            {
                type:'tel',
                value: '(480) 555-0103'
            },
            {
                type:'address',
                value: '4517 Washington Ave. '
            },
            {
                type:'email',
                value: 'debra.holt@example.com'
            }
        ]
    },

]

export default footerMenuItems;