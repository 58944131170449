import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardMedia } from '@mui/material';




export default function ScoreCard({score,caption,logo,bgColor,elevation,borderRadius}) {


    
    const styles = {
        mainContainer: {
            display:'flex',
            flexDirection:{xs:'column',md:'column'},
            alignItems:'center',
            width:{xs:'70%',sm:'100%',md:'230px'},      
            backgroundColor:bgColor,
            borderRadius: (borderRadius === null) ? 0 : borderRadius,
        },
        score: {
            color:score.color,
            // fontFamily: score.fontFamily,
            fontSize:score.fontSize,
            fontStyle: score.fontStyle,
            fontWeight: score.fontWeight,
            lineHeight: score.lineHeight,
            textAlign:'center',
        },
        caption: {
            color:caption.color,
            // fontFamily: caption.fontFamily,
            fontSize:caption.fontSize,
            fontStyle: caption.fontStyle,
            fontWeight: caption.fontWeight,
            lineHeight: caption.lineHeight,
            textAlign:'center',
        }
    }

  return (
    <Card  elevation = {elevation} sx={styles.mainContainer}>
      <CardMedia 
        component="img"
        sx = {{
          width:'60px',
          mt:'30px',
          mx:'0px'

        }}
        image={logo}
      />
      <CardContent sx = {{mx:'0px',px:'0'}}>
        <Typography id = 'score' sx={styles.score} gutterBottom>
          {score.text}
        </Typography>
        <Typography id = 'caption' sx={styles.caption} component="div">
          {caption.text}
        </Typography>
      </CardContent>
    </Card>
  );
}
