import { useMediaQuery,useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PracticeAdviceCard from "./PracticeAdviceCard";


import adviceImg1 from './../assets/advice1.png';
import adviceImg2 from './../assets/advice2.png';
import adviceImg3 from './../assets/advice3.png';
import adviceImg4 from './../assets/advice4.png';

export default function PracticeAdviceSection() {
    const {t} = useTranslation(['main']);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('sm'));
    const isSM = useMediaQuery(theme.breakpoints.between('sm','md'));
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    const styles = {
        mainContainer:{
            mx:'auto',
            mt:'250px',

        },
        titlesContainer: {
            width:{xs:'80%',sm:'100%',md:'100%'},
            mx:'auto'

        },
        title: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '40px',
            lineHeight: '57px',
            letterSpacing: '0.2px',
            color: '#252B42',
            textAlign:'center',
        },
        subTitle: {
            width:{xs:'100%',sm:'40%',md:'511px'},
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            letterSpacing: '0.2px',
            color: '#737373',
            mx:'auto',
            mt:'10px',
        },
        scoreCardsContainer: {
            display:{xs:'flex',sm:'grid',md:'flex'},
            gridTemplateColumns: {sm:'repeat(2,1fr)'},
            flexDirection:{xs:'column',sm:'column',md:'row'},
            alignItems:{xs:'center',sm:'end',md:'center'},
            alignContent:'space-between;',
            justifyItems: 'center',
            gap:'38px',
            mt:'50px',
            padding: isXS ? '0' :
                     isSM ? '30px' :
                     isMD ? '0' : '0',
        }


    }
    return  (
        <Box
            id = "main-container"
            maxWidth="lg"
            sx = {styles.mainContainer}
        >
            <Box
                id = "titles-container"
                sx = {styles.titlesContainer}
            >
                <Typography sx = {styles.title}>{t('advicesHeaders.0.title')}</Typography>
                <Typography sx = {styles.subTitle}>{t('advicesHeaders.1.caption')}</Typography>
            </Box>
            <Box
                id = "score-cards-container"
                sx = {styles.scoreCardsContainer}
            >
                <PracticeAdviceCard 
                    title = {
                        {
                            text:t('adviceBox1.0.title'),
                            color:'#252B42',
                            // fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    text = {
                        {
                            text:t('adviceBox1.1.caption'),
                            color:'#737373',
                            // fontFamily: 'inherit',
                            fontSize:'14px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '20px',
                        }
                    }
                    img = {adviceImg1}
                    bgColor = {'#fff'}
                    elevation = {3}
                    borderRadius = {0}
                />
                <PracticeAdviceCard 
                    title = {
                        {
                            text:t('adviceBox2.0.title'),
                            color:'#252B42',
                            // fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    text = {
                        {
                            text:t('adviceBox2.1.caption'),
                            color:'#737373',
                            // fontFamily: 'inherit',
                            fontSize:'14px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '20px',
                        }
                    }
                    img = {adviceImg2}
                    bgColor = {'#fff'}
                    elevation = {3}
                    borderRadius = {0}
                />
                <PracticeAdviceCard 
                    title = {
                        {
                            text:t('adviceBox3.0.title'),
                            color:'#252B42',
                            // fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    text = {
                        {
                            text:t('adviceBox3.1.caption'),
                            color:'#737373',
                            // fontFamily: 'inherit',
                            fontSize:'14px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '20px',
                        }
                    }
                    img = {adviceImg3}
                    bgColor = {'#fff'}
                    elevation = {3}
                    borderRadius = {0}
                />
                <PracticeAdviceCard 
                    title = {
                        {
                            text:t('adviceBox4.0.title'),
                            color:'#252B42',
                            // fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    text = {
                        {
                            text:t('adviceBox4.1.caption'),
                            color:'#737373',
                            // fontFamily: 'inherit',
                            fontSize:'14px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '20px',
                        }
                    }
                    img = {adviceImg4}
                    bgColor = {'#fff'}
                    elevation = {3}
                    borderRadius = {0}
                />
            </Box>

        </Box>
    )
}