import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SocialMediaIconsBar from './SocialMediaIconsBar';



export default function Copyright() {
    const {t} = useTranslation(['main']);
    const styles = {
        mainContainer: {
            display:'flex',
            flexDirection:{xs:'column',sm:'row',md:'row'},
            alignItems:{xs:'start',sm:'start',md:'center'},
            justifyContent:'end',
            gap: {xs:'50px',sm:'30px',md:'0'},
            width:{xs:'80%',sm:'80%',md:'100%'},
            mx:'auto',
            my:'30px',
        },
        text: {
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineEight: '24px',
            letterSpacing: '0.2px',
            color: '#737373',
            width:'100%',
        }
    }
    return (
        <Box maxWidth = "lg" sx = {styles.mainContainer}>
            <Typography sx = {styles.text}>{t('copyright')}</Typography>
            <SocialMediaIconsBar />
        </Box>
    )
}