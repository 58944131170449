import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import VideoPlayer from './VideoPlayer';





export default function BestBussinessServiceSection() {

    const {t} = useTranslation(['main']);
    const styles = {
        mainContainer:{
            mx:'auto',
            mt:'250px',

        },
        titlesContainer: {
            width:{xs:'80%',sm:'100%',md:'100%'},
            mx:'auto'

        },
        title: {
            width:{xs:'100%',sm:'100%',md:'480px'},
            mx:'auto',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'30px',sm:'30px',md:'40px'},
            lineHeight: '57px',
            letterSpacing: '0.2px',
            color: '#252B42',
            textAlign:'center',
        },
        subTitle: {
            width:{xs:'100%',sm:'80%',md:'511px'},
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            letterSpacing: '0.2px',
            color: '#737373',
            mx:'auto',
            mt:'10px',
        },
        contentContainer: {

        }


    }
    return  (
        <Box
            id = "main-container"
            maxWidth="lg"
            sx = {styles.mainContainer}
        >
            <Box
                id = "titles-container"
                sx = {styles.titlesContainer}
            >
                <Typography sx = {styles.title}>{t('servicesHears.0.title')}</Typography>
                <Typography sx = {styles.subTitle}>{t('servicesHears.1.caption')}</Typography>
            </Box>
            <Box
                id = "score-cards-container"
                sx = {styles.contentContainer}
            >
                <VideoPlayer />
                <Box></Box>
            </Box>

        </Box>
    )
}