

const listOfCustomers = [
    {
        name:'hooli',
        logo:'hooli.png'
    },
    {
        name:'lya',
        logo:'lya.png'
    },
    {
        name:'co3',
        logo:'co-3.png'
    },
    {
        name:'stripe',
        logo:'stripe.png'
    },
    {
        name:'aws',
        logo:'aws.png'
    },
    {
        name:'reddit',
        logo:'reddit.png'
    },
]


export  default listOfCustomers