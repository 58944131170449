import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import footerMenuItems from './footerMenuItems';
import FooterSubmenu from './FooterSubmenu';
import ContactsBar from './ContactsBar';

export default function FooterMenuBar() {
    const {t} = useTranslation(['main']);
    const styles = {
        mainContainer: {
            backgroundColor:'#16697A',
            
        },
        contentContainer: {
            width:{xs:'100%',sm:'80%',md:'100%'},
            mx:'auto',
            display:{xs:'flex',sm:'grid',md:'flex'},
            flexDirection:{xs:'column',sm:'column',md:'row'},
            gridTemplateColumns:{sm:'repeat(2,1fr)'},
            justifyItems:'start',
            gap:{xs:'0px',sm:'10px',md:'30px'},
            paddingTop:'30px',
        }
    }


    const menuItems = footerMenuItems.map((item,index) => {
        return (
            <FooterSubmenu key = {index} items = {item}/>

        )
    })

    return (
        <Box
            sx = {styles.mainContainer}
        >
            <Box maxWidth = "lg" sx = {styles.contentContainer}>
                {menuItems}
            </Box>

        </Box>
    )
}