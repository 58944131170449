import { useTranslation } from "react-i18next";
import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SendIcon from '@mui/icons-material/Send';



export default function FooterSubmenu({items}) {
    const {t} = useTranslation(['main']);


    const styles = {
        Stack: {
            border:'none',
            padding:{xs:'10px',sm:'0',md:'30px'},
            boxShadow: 'none',
        },
        paper:{
            backgroundColor:'transparent',
            boxShadow: 'none',
        },
        menuTitle: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.1px',
            color: '#FFFFFF',
            marginBottom:'15px'
        },
        menuItem: {
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            gap:'30px',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.2px',
            color: '#FFFFFF',
            flex: 'none',
            order: '0',
            flexGrow: '0',
        },
        icons: {
            color:'#8EC2F2'
        }
    }

    const iconsOfItems = {
        tel: <LocalPhoneIcon sx = {styles.icons}/>,
        address: <LocationOnIcon sx = {styles.icons}/>,
        email: <SendIcon sx = {styles.icons}/>,
    };

    

    const menuItems = items.subItems.map((item, index) => {
        return items.title !== 'Get In Touch'
        ? (
            <MenuItem key={index} sx={styles.menuItem}>
                {t(`${items.translateName}.${index+1}.item${index+1}`)}
            </MenuItem>
        ) : (
            <MenuItem key={index} sx={styles.menuItem}>
                {iconsOfItems[item.type]}
                {t(`${items.translateName}.${index+1}.item${index+1}`)}
            </MenuItem>
        );
    });
    

    

  return (
    <Stack direction="row" spacing={2} sx = {styles.Stack}>
      <Paper sx = {styles.paper} >
        <MenuList>
            <MenuItem sx = {styles.menuTitle}>{t(`${items.translateName}.0.title`)}</MenuItem>
            <Divider />
            {menuItems}
        </MenuList>
      </Paper>
    </Stack>
  );
}
