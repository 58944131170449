import { useTranslation } from "react-i18next";
import { useMediaQuery,useTheme } from "@mui/material";
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Input from "@mui/material/Input"
import { borderRadius } from "@mui/system"


export default function NewsleterSubscriber() {
    const {t} = useTranslation(['main']);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('sm'));
    const isSM = useMediaQuery(theme.breakpoints.between('sm','md'));
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    const styles = {
        mainContainer: {
            width:{xs:'100%',sm:'90%',md:'50%'},
            mb:'100px',
            mx:'auto',
            padding: isXS ? '30px 0' :
                     isSM ? '30px 48px' :
                     isMD ? '30px 48px' : '0',
            display:'flex',
            flexDirection:{xs:'column',sm:'row',md:'row'},
            alignItems:'center',
            justifyContent:'space-between',
            gap:'30px',
            backgroundColor:'#16697A',

        },
        title: {
            width:{xs:'100%',sm:'100%',md:'30%'},
            textAlign:{xs:'center',sm:'center',md:'justify'},
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '32px',
            letterSpacing: '0.1px',
            color: '#FFFFFF',
        },
        inputContainer: {    
            display:'flex',
            flexDirection:'row',
            alignItems:'center',        
            width:{xs:'90%',sm:'100%',md:'45%'},
            border:'1px solid #fff',
            borderRadius:'10px'
        },
        textfield: {
            width:'100%',
            height:'58px',
            borderRadius:'10px 0 0 10px',
            backgroundColor:'#fff',
            padding:'10px 20px',


        },
        button: {
            backgroundColor:'#FFA62B',
            border:'none',
            borderRadius:'0 10px 10px 0',
            height:'58px'
        }
    }
    return (
        <Box maxWidth = "lg" sx = {styles.mainContainer}>
            <Typography sx = {styles.title}>{t('newsLetterTtle')}</Typography>
            <Box  sx = {styles.inputContainer}>
                <Input 
                    variant="standard"
                    disableUnderline
                    sx = {styles.textfield}
                    placeholder={t('emailPlaceHolder')}
                />
                <Button variant="contained" sx = {styles.button}>{t('newsLetterButton')}</Button>
            </Box>            

        </Box>
    )
}