import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardMedia } from '@mui/material';
import { Height } from '@mui/icons-material';



export default function PracticeAdviceCard({title,text,img,bgColor,elevation,borderRadius}) {


  
    const styles = {
        mainContainer: {
            width:'238px',
            Height:'341px',
            minWidth: 275,
            backgroundColor:bgColor,
            borderRadius: (borderRadius === null) ? 0 : borderRadius,
        },
        title: {
            color:title.color,
            fontFamily: title.fontFamily,
            fontSize:title.fontSize,
            fontStyle: title.fontStyle,
            fontWeight: title.fontWeight,
            lineHeight: title.lineHeight,
            mx:'30px',
            mt:'30px',
            mb:'15px'
        },

        text: {
            color:text.color,
            fontFamily: text.fontFamily,
            fontSize:text.fontSize,
            fontStyle: text.fontStyle,
            fontWeight: text.fontWeight,
            lineHeight: text.lineHeight,
            mx:'30px',
            mb:'30px'
        }
    }

  return (
    <Card  elevation = {elevation} sx={styles.mainContainer}>
      <CardContent sx = {{mx:'0px',px:'0'}}>
        <Typography id = 'title' sx={styles.title} gutterBottom>
          {title.text}
        </Typography>

        <Typography id = 'text' sx={styles.text}>
          {text.text}
        </Typography>
      </CardContent>
      <CardMedia 
        component="img"
        sx = {{
          width:'100%',
        }}
        image={img}
      />
    </Card>
  );
}
