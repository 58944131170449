import * as React from 'react';
import { useMediaQuery,useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import listOfCustomers from './listOfCustomers';








export default function BasicStack() {


  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  const isSM = useMediaQuery(theme.breakpoints.between('sm','md'));
  const isMD = useMediaQuery(theme.breakpoints.up('md'));

  const styles =  {
    mainContainer: {
      mx:'auto',
      my:'150px',
      width: '100%'
    },
    stack: {
      width:{xs:'50%',sm:'80%',md:'100%'},
      padding:{xs:'10px',sm:'0',md:'0'},
      height:{xs:'100%',sm:'300px',md:'100%'},
      mx:'auto',
    },
    imageContainer: {
      backgroundColor:'transparent',
      width:{xs:'100%',sm:'30%',md:'10%'},      
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center',
    },
    imgStyle: {
      width: isXS ? '50%' :
             isSM ? '60%' :
             isMD ? '100%' : '100%'
    }
  }
  
  const customers = listOfCustomers.map((item,index) => {
    return (
      <Box
        key = {index}
        sx = {styles.imageContainer}
      >
        <img src = {require(`./../assets/logos/${item.logo}`)} style = {styles.imgStyle} alt = {item.name}/>
      </Box>
    )
  })

  return (
    <Box maxWidth = "lg" sx={styles.mainContainer}>
      <Stack spacing={{xs:5,sm:1,md:10}} direction={{xs:'column',sm:'row',md:"row"}} flexWrap = "wrap" sx = {styles.stack}>
        {customers}
      </Stack>
    </Box>
  );
}
