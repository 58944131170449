import Container from "@mui/material/Container"
import Box from "@mui/material/Box"

import HomePageHeroSection from './../components/HomePageHeroSection';
import OurAdvantagesSection from './../components/OurAdvantagesSection';
import PracticeAdviceSection from './../components/PracticeAdviceSection';
import BestBussinessServiceSection from './../components/BestBussinessServiceSection';
import CustomersSection from '../components/CustomersSection';
import NewsleterSubscriber from '../components/NewsleterSubscriber'
import GetInTouchSection from './../components/GetInTouchSection'
import BackTop from './../components/BackTop';

export default function HomePage() {
    const styles = {
        mainContainer: {
            maxWidth:'none',
            width:'100%',
            height:'100%',
            // border:'10px solid red'

        },

    }
    return (
        <Container
            disableGutters = {true}
            id = "main-container"
            style = {styles.mainContainer}
            maxWidth = "false"
        >
            <HomePageHeroSection />
            <OurAdvantagesSection />
            <PracticeAdviceSection />
            <BestBussinessServiceSection />
            <CustomersSection />
            <NewsleterSubscriber />
            <GetInTouchSection />
            <BackTop />

        </Container>
    )
}