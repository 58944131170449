import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useMediaQuery,useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import ScoreCard from "./ScoreCard";
import scoreIcone1 from './../assets/icons/scoreIcon1.png';
import scoreIcone2 from './../assets/icons/scoreIcon2.png';
import scoreIcone3 from './../assets/icons/scoreIcon3.png';
import scoreIcone4 from './../assets/icons/scoreIcon4.png';

export default function OurAdvantagesSection() {
    const {t} = useTranslation(['main']);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('sm'));
    const isSM = useMediaQuery(theme.breakpoints.between('sm','md'));
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    const styles = {
        mainContainer:{
            mx:'auto',
            mt:{xs:'100px',sm:'100px',md:'250px'},

        },
        titlesContainer: {

        },
        title: {
            width:{xs:'60%',sm:'100%',md:'100%'},
            mx:'auto',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'30px',sm:'30px',md:'40px'},
            lineHeight: '57px',
            letterSpacing: '0.2px',
            color: '#252B42',
            textAlign:'center',
        },
        subTitle: {
            width:{xs:'50%',sm:'100%',md:'511px'},
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            letterSpacing: '0.2px',
            color: '#737373',
            mx:'auto',
            mt:'10px',
        },
        scoreCardsContainer: {
            display:{xs:'flex',sm:'grid',md:'flex'},
            flexDirection:{xs:'column',sm:'row',md:'row'},
            gridTemplateColumns: {sm:'repeat(2,1fr)'},
            alignItems:{xs:'center',sm:'center',md:'center'},
            justifyContent:'center',
            gap:{xs:'38px',sm:'50px',md:'38px'},
            mt:'50px',
            padding:isXS ? '0px' :
                    isSM ? '20px' :
                    isMD ? '30px' : '0'
        }


    }
    return  (
        <Box
            id = "main-container"
            maxWidth="lg"
            sx = {styles.mainContainer}
        >
            <Box
                id = "titles-container"
                sx = {styles.titlesContainer}
            >
                <Typography sx = {styles.title}>{t('whyWeSectionHeaders.0.title')}</Typography>
                <Typography sx = {styles.subTitle}>{t('whyWeSectionHeaders.1.caption')}</Typography>
            </Box>
            <Box
                id = "score-cards-container"
                sx = {styles.scoreCardsContainer}
            >
                <ScoreCard 
                    score = {
                        {
                            text:t('whyWeSectionBox1.0.title'),
                            color:'#252B42',
                            // fontFamily: 'inherit',
                            fontSize:'40px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '57px',
                        }
                    }
                    caption = {
                        {
                            text:t('whyWeSectionBox1.1.caption'),
                            color:'#737373',
                            // fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    logo = {scoreIcone1}
                    bgColor = {'#fff'}
                    elevation = {3}
                    borderRadius = {0}
                />
                <ScoreCard 
                    score = {
                        {
                            text:t('whyWeSectionBox2.0.title'),
                            color:'#252B42',
                            // fontFamily: 'inherit',
                            fontSize:'40px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '57px',
                        }
                    }
                    caption = {
                        {
                            text:t('whyWeSectionBox2.1.caption'),
                            color:'#737373',
                            // fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    logo = {scoreIcone2}
                    bgColor = {'#fff'}
                    elevation = {3}
                    borderRadius = {0}
                />
                <ScoreCard 
                    score = {
                        {
                            text:t('whyWeSectionBox3.0.title'),
                            color:'#252B42',
                            // fontFamily: 'inherit',
                            fontSize:'40px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '57px',
                        }
                    }
                    caption = {
                        {
                            text:t('whyWeSectionBox3.1.caption'),
                            color:'#737373',
                            // fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    logo = {scoreIcone3}
                    bgColor = {'#fff'}
                    elevation = {3}
                    borderRadius = {0}
                />
                <ScoreCard 
                    score = {
                        {
                            text:t('whyWeSectionBox4.0.title'),
                            color:'#252B42',
                            // fontFamily: 'inherit',
                            fontSize:'40px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '57px',
                        }
                    }
                    caption = {
                        {
                            text:t('whyWeSectionBox4.1.caption'),
                            color:'#737373',
                            // fontFamily: 'inherit',
                            fontSize:'16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }
                    }
                    logo = {scoreIcone1}
                    bgColor = {'#fff'}
                    elevation = {3}
                    borderRadius = {0}
                />
            </Box>

        </Box>
    )
}