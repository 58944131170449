import { Suspense } from 'react';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavBar from './components/NavBar';
import HomePage from './Pages/HomePage';
import ProductPage from './Pages/ProductPage';
import PricingPage from './Pages/PricingPage';
import ContactPage from './Pages/ContactPage';
import Box from '@mui/material/Box'
import Footer from './components/Footer';
import ScrollTop from './ScrollTop';



function App() {
  
  const {t,i18n} = useTranslation(['common']);

  const loadingPageStyle = {
    width:'100vw',
    height:'100vh',
    backgroundColor: 'rgb(0,0,0)',
    background: 'linear-gradient(90deg, rgba(25,12,247,1) 15%, rgba(5,99,251,0.0046219171262255054) 82%)',    
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  }
  const loadingTextStyle = {
    color:"#fff",
    fontSize:'30px',
    textAlign:'center',
    fontWeight:'bold',
    direction: i18n.dir()
  }


  
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollTop />
        <NavBar />
        <Suspense
          fallback = {
            <div style = {loadingPageStyle}>
              <h1 style = {loadingTextStyle}>{t('loading')}</h1>
            </div>
          }
        >
          <Routes>
              <Route exact path = "/" element = {<HomePage />}/>
              <Route path = "/home" element = {<HomePage />}/>
              <Route path = "/product" element = {<HomePage />}/>
              <Route path = "/pricing" element = {<HomePage />}/>
              <Route path = "/contact" element = {<HomePage />}/>
          </Routes>

        </Suspense>
        <Box sx = {{backgroundColor:'#fff',width:'100vw',py:{xs:'20px',sm:'40px',md:'30px'}}}>
            <Footer />
        </Box>
      </BrowserRouter>
      
      

    </div>
  );
}

export default App;