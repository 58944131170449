import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardMedia } from '@mui/material';




export default function submitCard({boxHeight,email1,email2,title,btnText,btnColor,logo,bgColor,elevation,borderRadius}) {


    const styles = {
        mainContainer: {
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            width:{xs:'80%',sm:'50%',md:'328px'},
            height:boxHeight,
            // minWidth: 275,
            backgroundColor:bgColor,
            borderRadius: (borderRadius === null) ? 0 : borderRadius,
        },
        title: {
            color:title.color,
            fontFamily: title.fontFamily,
            fontSize:title.fontSize,
            fontStyle: title.fontStyle,
            fontWeight: title.fontWeight,
            lineHeight: title.lineHeight,
            my:'15px',
            textAlign:'center',
        },
        email1: {
            color:email1.color,
            fontFamily: email1.fontFamily,
            fontSize:email1.fontSize,
            fontStyle: email1.fontStyle,
            fontWeight: email1.fontWeight,
            lineHeight: email1.lineHeight,
            mt:'15px',
            textAlign:'center',
        },
        email2: {
            color:email2.color,
            fontFamily: email2.fontFamily,
            fontSize:email2.fontSize,
            fontStyle: email2.fontStyle,
            fontWeight: email2.fontWeight,
            lineHeight: email2.lineHeight,
            textAlign:'center',
        },
        btnText: {
          backgroundColor:'transparent',
          borderColor:btnColor,
          color:btnColor,
          fontFamily: btnText.fontFamily,
          fontSize:btnText.fontSize,
          fontStyle: btnText.fontStyle,
          fontWeight: btnText.fontWeight,
          lineHeight: btnText.lineHeight,
          my:'15px',
          '&:hover': {
            backgroundColor:btnColor,
            color:bgColor
          },
      }
    }

  return (
    <Card  elevation = {elevation} sx={styles.mainContainer}>
      <CardMedia 
        component="img"
        sx = {{
          width:'60px',
          mt:'30px',
          mx:'0px'

        }}
        image={logo}
      />
      <CardContent sx = {{mx:'0px',px:'0'}}>
        <Typography id = 'email1' sx={styles.email1} gutterBottom>
          {email1.text}
        </Typography>
        <Typography id = 'email2' sx={styles.email2} gutterBottom>
          {email2.text}
        </Typography>
        <Typography id = 'title' sx={styles.title} component="div">
          {title.text}
        </Typography>
        <Box sx = {{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Button variant="outlined" id="btn" sx = {styles.btnText}>
            {btnText.text}
          </Button>
        </Box>

      </CardContent>
    </Card>
  );
}
